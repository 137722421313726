@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 1px solid var(--primary);
}

.leaflet-routing-dragline {
  display: none;
  /* Hide the drag line */
}

.leaflet-routing-waypoint {
  cursor: default;
  /* Change cursor to default to indicate non-draggable */
}

.leaflet-routing-waypoint .leaflet-routing-waypoint-icon {
  display: none;
  /* Hide the waypoint icon */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.background-image {
  background-image: url('/src/assets/images/login-bg-image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}